import { BreadcrumbsProps } from "./types";
import styles from "./BreadcrumbsV2.module.scss";
import clsx from "clsx";
import ArrowDouble from "../../public/static-pages/icons/arrow-double.svg";
import ArrowDoubleBack from "../../public/static-pages/icons/arrow-double-back.svg";

export function BreadcrumbsUI({
  links,
  standalone,
}: {
  links: BreadcrumbsProps[];
  standalone?: boolean;
}) {
  const length = links.length;

  if (length <= 1) return null;

  const itemListElement = links.map((link, index) => {
    return {
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": process.env.NEXT_PUBLIC_SPLUS_BASE_URL + link.link,
        name: link.label,
      },
    };
  });
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: itemListElement,
  };

  const simplifiedLink = links[length - 2];

  return (
    <>
      {/* Breadcrumbs structured data */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <nav
        aria-label="Breadcrumbs"
        className={clsx(styles.breadcrumbs, {
          [styles.breadcrumbsStandalone]: standalone,
        })}
      >
        <ol className={styles.breadcrumbsList}>
          {links.map((link, index) => (
            <li key={index}>
              <a
                href={link.link}
                aria-current={index + 1 === length ? "page" : undefined}
                className={clsx(styles.breadcrumbsLink, {
                  [styles.breadcrumbsLinkCurrent]: index + 1 === length,
                })}
              >
                {link.label}
              </a>
              {index + 1 < length && <ArrowDouble />}
            </li>
          ))}
        </ol>
        <div className={styles.breadcrumbsMobile}>
          <ArrowDoubleBack />
          <a href={simplifiedLink.link} className={styles.breadcrumbsLink}>
            See {simplifiedLink.label}
          </a>
        </div>
      </nav>
    </>
  );
}
