import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { extractLabelAndLink } from "services/textHelpers";
import { BreadcrumbsUI } from "./BreadcrumbsV2.helpers";
import { BreadcrumbsContent } from "./types";

export function BreadcrumbsV2({
  component,
  page,
}: BrProps<ContainerItem>): React.ReactElement | null {
  if (!component || !page) {
    return null;
  }

  const content = getContainerItemContent<BreadcrumbsContent>(component, page);
  const breadcrumbsLinks = content?.breadcrumb.map((crumb) =>
    extractLabelAndLink(crumb)
  );

  return (
    <div>
      {breadcrumbsLinks ? (
        <BreadcrumbsUI links={breadcrumbsLinks} standalone />
      ) : null}
    </div>
  );
}
